import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form, Image } from "react-bootstrap";
import { GrFormViewHide } from "react-icons/gr";
import { customStyles } from "./data";
import http from "../http.common.function";
import { ImageApiURL } from "../path";
import Header from "./Header";

export default function Proposal() {
  const [BlogData, setBlogData] = useState([]);
  const [ViewData, setViewData] = useState(null);
  const [isView, setIsView] = useState(false);
  const [SearchValue, setSearchValue] = useState("");

  const columns = [
    {
      name: "Full name",
      selector: (row) => row?.fullname,
    },
    {
      name: "Company name",
      selector: (row) => row?.companyname,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Mobile no",
      selector: (row) => row?.mobileno,
    },
    {
      name: "Max Participants",
      selector: (row) => row?.max,
    },
    {
      name: "Workshop Date",
      selector: (row) => row?.workshopDate,
    },
    {
      name: "ACTION",
      cell: (row) => (
        <div className="row">
          <span className="hyperlink col-md-3" style={{ cursor: "pointer" }}>
            <i
              onClick={() => deleteProposal(row._id)}
              className="fa fa-trash"
              title="Delete"
              style={{ color: "#dc3545" }}
            ></i>{" "}
            |{" "}
          </span>
          <span className="hyperlink col-md-3" style={{ cursor: "pointer" }}>
            <GrFormViewHide
              onClick={() => handleView(row)}
              style={{ color: "#6EACDA" }}
            />{" "}
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getProposal();
  }, [SearchValue]);

  const getProposal = async () => {
    try {
      const response = await http.get(`/proposal/getproposal`, {
        params: { searchValue: SearchValue },
      });
      setBlogData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching proposal data", error);
    }
  };

  const deleteProposal = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this proposal?");

    if (confirmed) {
      try {
        const response = await http.post(`/proposal/trash/${id}`);
        if (response.status === 200) {
          alert("Proposal deleted successfully");
          getProposal(); // Refresh data without reloading the page
        }
      } catch (error) {
        console.error("Error deleting proposal", error);
      }
    }
  };

  const handleView = (row) => {
    setViewData(row);
    setIsView(true);
  };

  return (
    <>
       
      {!isView ? (
        <>
          <div className="row mt-4">
            <div className="col-md-9"></div>
            <div className="col-md-3 m-auto float-end">
              <Form.Control
                value={SearchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="fs_12"
                placeholder="Search for name or number here..."
              />
            </div>
          </div>
          <DataTable
            className="mt-3"
            columns={columns}
            data={BlogData}
            highlightOnHover
            pointerOnHover
            pagination
            selectableRows
            bordered
            customStyles={customStyles}
          />
        </>
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-md-10 m-auto"></div>
            <button
              onClick={() => setIsView(false)}
              className="col-md-2 btn_bg p-2 m-auto float-end"
            >
              View list
            </button>
          </div>
          <div className="row">
            <div className="col-md-7">
              <p>
                <span className="sub-sub_heading me-3">Full name:</span>{" "}
                {ViewData?.fullname}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Company name:</span>{" "}
                {ViewData?.companyname}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Email:</span>{" "}
                {ViewData?.email}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Mobile No.:</span>{" "}
                {ViewData?.mobileno}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Tentative Participants:</span>{" "}
                {ViewData?.max}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Workshop Date:</span>{" "}
                {ViewData?.workshopDate}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Message:</span>{" "}
                {ViewData?.message}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
