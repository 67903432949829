import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Form, Card, Image, Row, Col } from "react-bootstrap";
import { GrFormViewHide } from "react-icons/gr";
import { customStyles } from "./data";
import Offcanvas from "react-bootstrap/Offcanvas";
import http from "../http.common.function";
import { ImageApiURL } from "../path";

export default function Contactdetails() {


  const [Contactdata, setContactdata] = useState();
  const [ViewData, setViewData] = useState();
  const [isView, setisView] = useState(false);
  const [SearchValue, setSearchValue] = useState("");

  const columns = [
    {
      name: "Full name",
      selector: (row) => row?.fullname,
    },
    {
      name: "Company name",
      selector: (row) => row?.companyname,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "Mobile no",
      selector: (row) => row?.mobileno,
    },
    {
      name: "Mobile no",
      selector: (row) => row?.mobileno,
    },

    {
      name: "ACTION",
      cell: (row) => (
        <div className="row">
          <span className="hyperlink col-md-3" style={{ cursor: "pointer" }}>
            <i
              onClick={() => deleteContact(row._id)}
              className="fa fa-trash"
              title="Delete"
              style={{ color: "#dc3545" }}
            ></i>{" "}
            |{" "}
          </span>
          <span className="hyperlink col-md-3" style={{ cursor: "pointer" }}>
            <GrFormViewHide
              onClick={() => handleView(row)}
              style={{ color: "#6EACDA" }}
            />{" "}
          </span>
        </div>
      ),
    },
  ];


  useEffect(() => {
    getContact();
  }, [SearchValue]);


  const getContact = async () => {
    try {
      let Blog = await http.get(`/cont/getcontact`, {
        params: { searchValue: SearchValue },
      });
      setContactdata(Blog.data.data);
    } catch (error) {
      // console.log("Error fetching Blog data", error);
    }
  };

  const deleteContact = async (idd) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete this Blog?`
    );

    if (confirmed) {
      let data = await http.post(`/cont/trash/${idd}`);
      if (data.status === 200) {
        alert("Blog deleted succesfully ");
        window.location.reload();
      }
    } else {
      // console.log("Blog canceled the deletion.");
    }
  };

  const handleView = (row) => {
    setViewData(row);
    setisView(true);
  };
  return (
    <>
      {!isView ? (
        <>

          <div className="row mt-4">
            <div className="col-md-9"></div>
            <div className="col-md-3 m-auto float-end">
              <Form.Control
                value={SearchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="fs_12"
                placeholder="Search for name or companyname here..."
              />
            </div>
          </div>
          <DataTable
            className="mt-3"
            columns={columns}
            data={Contactdata}
            highlightOnHover
            pointerOnHover
            pagination
            selectableRows
            bordered
            customStyles={customStyles}
          />
        </>
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-md-10 m-auto"></div>
            <button
              onClick={() => setisView(false)}
              className="col-md-2 btn_bg p-2 m-auto float-end"
            >
              View list
            </button>
          </div>
          <div className="row">
            <div className="col-md-7">
              <p>
                <span className="sub-sub_heading me-3">Full name:</span>{" "}
                {ViewData?.fullname}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Company name:</span>{" "}
                {ViewData?.companyname}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Email:</span>{" "}
                {ViewData?.email}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Mobile No.:</span>{" "}
                {ViewData?.mobileno}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Tentative Participants:</span>{" "}
                {ViewData?.max}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Workshop Date:</span>{" "}
                {ViewData?.workshopDate}
              </p>
              <p>
                <span className="sub-sub_heading me-3">Message:</span>{" "}
                {ViewData?.message}
              </p>
            </div>
          </div>
        </>
      )}

    </>
  );
}
